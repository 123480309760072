import { useState, useEffect } from 'react';

const useCountdownTimer = (targetDate: string) => {
  const calculateTimeLeft = (date: string) => {
    const now = new Date();
    const then = new Date(date);
    const diff = (then.getTime() - now.getTime()) / 1000; // Difference in seconds

    if (diff <= 0) {
      return '0';
    }

    if (diff >= 86400) {
      // More than 24 hours
      const days = Math.floor(diff / 86400);
      const hours = Math.floor((diff % 86400) / 3600);
      const minutes = Math.floor((diff % 3600) / 60);

      return `${days.toString().padStart(2, '0')}d:${hours.toString().padStart(2, '0')}h:${minutes
        .toString()
        .padStart(2, '0')}m`;
    }

    // More than 1 hour
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    const seconds = Math.floor(diff % 60);

    return `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds
      .toString()
      .padStart(2, '0')}s`;

    // Less than 1 minute
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [targetDate]);

  return timeLeft;
};

export default useCountdownTimer;
